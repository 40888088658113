import React from 'react'

function ComingSoon() {
    return (
        <div class="container mx-auto py-40">
            <div class="h-96  p-4  justify-center items-center flex rounded-3xl border-blue-500">
            {/* <div class="box-border h-96  p-4 border-4 justify-center items-center flex rounded-3xl border-blue-500"> */}
                <p class="text-9xl text-center text-blue-600">Coming Soon</p>
            </div>

        </div>
    )
}

export default ComingSoon
