import './App.css';
import Hero from './Components/Hero'
import CommingSoon from './Components/ComingSoon'


function App() {
  return (
    <div> 
      <Hero />
      <CommingSoon />
    </div>
  );
}

export default App;
